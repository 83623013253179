import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { Bind } from "../../decorators/bind.decorator";
import { S25EditMasterDefinitionTagComponent } from "./s25.edit.master.defs.tag.component";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { TelemetryService } from "../../services/telemetry.service";

@TypeManagerDecorator("s25-ng-edit-tag-modal")
@Component({
    selector: "s25-ng-edit-tag-modal",
    template: ` <div *ngIf="init">
        <button (click)="initModal(editTagModal)" class="create-tag-button {{ buttonClass }}">
            {{ buttonText }}
        </button>

        <s25-ng-modal #editTagModal [title]="title" [size]="'xl'" class="edit-tag-modal">
            <ng-template #s25ModalBody>
                <s25-edit-master-definition-tag [itemId]="this.itemId"></s25-edit-master-definition-tag>
            </ng-template>
            <ng-template #s25ModalFooter>
                <div class="ngRed" *ngIf="errorMsg">{{ errorMsg }}</div>
                <div class="buttons">
                    <button class="aw-button aw-button--primary" (click)="onSave()">Save</button>
                    <button class="aw-button aw-button--outline cancel" (click)="onCancel()">Cancel</button>
                </div>
            </ng-template>
        </s25-ng-modal>
    </div>`,
    styles: `
        .buttons {
            padding-top: 1em;
            display: flex;
            gap: 0.5em;
            position: sticky;
            bottom: 0;
            width: fit-content;
        }

        .buttons .cancel:not(:hover, :focus) {
            background-color: white !important;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
})
export class ModalEditTagComponent implements OnInit {
    @Input() itemId: number;
    @Input() title: string = "Edit Tag";
    @Input() buttonClass: string = "aw-button aw-button--outline";
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
    init = false;
    buttonText: string;
    errorMsg: string;

    @ViewChild(S25ModalComponent) modal: S25ModalComponent;
    @ViewChild(S25EditMasterDefinitionTagComponent) form: S25EditMasterDefinitionTagComponent;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        // this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        this.init = true;
        this.buttonText = this.itemId ? "Edit" : "Create New";
        if (!this.itemId) {
        }
        this.cd.detectChanges();
    }

    initModal(modal: S25ModalComponent) {
        return modal.open();
    }

    @Bind
    async onSave() {
        try {
            TelemetryService.sendWithSub("SysSettings", "Tags", "Create");
            await this.form.save();
            await this.modal.close();
            this.close.emit(true);
        } catch (e) {
            this.errorMsg = e;
            this.cd.detectChanges();
        }
    }

    @Bind
    async onCancel() {
        await this.modal.close();
        this.close.emit(false);
    }
}
