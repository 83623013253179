//@author: mandy
import { Cache } from "../../../../decorators/cache.decorator";

export class DashLangMap {
    @Cache({ targetName: "DashLangMap" })
    public static getLang(lang: any) {
        let controls = lang.div.controls,
            lcontroller = controls.controller,
            mycontrols = controls["s25-my25live"],
            ltext = mycontrols.text,
            ltooltips = mycontrols.tooltips,
            dashboard = ltext.dashboard;
        let fav = "My25Live_Favorite_Object";
        let list = {
            style: {
                1: {
                    panel: "My25Live_EventsSummary",
                    body: "My25Live_EventsSummary_Body",
                    item: "My25Live_EventLink",
                    styleClass: "no-border padding-right",
                },
                51: {
                    panel: "My25Live_DraftsSummary",
                    body: "My25Live_DraftsSummary_Body",
                    item: "My25Live_DraftLink",
                    styleClass: "no-border padding-right",
                },
                11: { panel: "My25Live_Favorites_Events", body: "My25Live_Favorites_Events_Body", item: fav },
                101: {
                    panel: "My25Live_Favorites_Event_Searches",
                    body: "My25Live_Favorites_Event_Searches_Body",
                    item: fav,
                },
                14: { panel: "My25Live_Favorites_Locations", body: "My25Live_Favorites_Locations_Body", item: fav },
                104: {
                    panel: "My25Live_Favorites_Location_Searches",
                    body: "My25Live_Favorites_Location_Searches_Body",
                    item: fav,
                },
                16: { panel: "My25Live_Favorites_Resources", body: "My25Live_Favorites_Resources_Body", item: fav },
                106: {
                    panel: "My25Live_Favorites_Resource_Searches",
                    body: "My25Live_Favorites_Resource_Searches_Body",
                    item: fav,
                },
                1000: { panel: "My25Live_Favorites_Reports", body: "My25Live_Favorites_Reports_Body", item: fav },
                1010: {
                    panel: "My25Live_TasksSummary",
                    body: "My25Live_TasksSummary_Body",
                    item: "My25Live_TasksSummary_Link",
                    styleClass: "no-border padding-right",
                },
                2000: { panel: "My25Live_RecentlyViewed", body: "My25Live_RecentlyViewed_Body", item: fav },
            },
            itemsnone: {
                1: { text: ltext.no_upcoming_events },
                2000: { style: "recently-viewed-none", text: "(none)" },
                11: {
                    text: ltext.no_starred_events,
                    desc: {
                        style: "icon-favorite-off",
                        a: ltext.mark_event_as_starred_1,
                        b: ltext.mark_event_as_starred_2,
                    },
                },
                14: {
                    text: ltext.no_starred_locations,
                    desc: {
                        style: "icon-favorite-off",
                        a: ltext.mark_location_as_starred_1,
                        b: ltext.mark_location_as_starred_2,
                    },
                },
                16: {
                    text: ltext.no_starred_resources,
                    desc: {
                        style: "icon-favorite-off",
                        a: ltext.mark_resource_as_starred_1,
                        b: ltext.mark_resource_as_starred_2,
                    },
                },
                101: { text: ltext.no_starred_event_searches },
                104: { text: ltext.no_starred_location_searches },
                106: { text: ltext.no_starred_resource_searches },
                1000: { text: ltext.no_starred_reports },
            },
            itemsloading: ltext.loading,
            // 'See when these locations are available'
            tail: {
                14: { text: ltext.fav_spaces_calendar_link },
                16: { text: ltext.fav_resources_calendar_link },
            },
        };
        return {
            panel: {
                type: function (typeId: number) {
                    switch (typeId) {
                        case 2000:
                            return "recently_viewed";
                        case 3000:
                            return "quick_search";
                        case 4000:
                            return "available_space";
                        case 5000:
                            return "create_event";
                        default:
                            return "list";
                    }
                },

                // 1000 - Your Starred Reports
                // 1010 - Task searches summary
                // 2000 - Recently Viewed
                // 3000 - Quick Search
                // 4000 - Find Available Locations
                // 5000 - Create an Event
                // 51   - Event Drafts
                head: {
                    col: {
                        a: {
                            icon: function (typeId: number, loggedIn: any) {
                                let composeFav = function (val: any, loggedIn: any) {
                                    return loggedIn ? val + "-fav" : val;
                                };
                                let map: any = {
                                    1: "icon-event",
                                    11: composeFav("icon-event", loggedIn),
                                    14: composeFav("icon-space", loggedIn),
                                    16: composeFav("icon-resource", loggedIn),
                                    51: "icon-draft",
                                    101: "icon-search",
                                    104: "icon-search",
                                    6: "icon-search",
                                    106: "icon-search",
                                    1000: "icon-reports",
                                    10: "icon-task",
                                    1010: "icon-task",
                                    2000: "icon-25live",
                                    3000: "icon-search",
                                    4000: "icon-rsrvwiz",
                                };
                                return map[typeId];
                            },
                        },
                        b: {
                            text: function (typeId: number, loggedIn: any, userTypeWeb: any) {
                                let compose = function (prefix: any, val: any) {
                                    return prefix + " " + val;
                                };
                                let map: any = {
                                    1: ltext.your_upcoming_events,
                                    51: ltext.your_event_drafts,
                                    11: {
                                        loggedIn: compose(ltext.header_prefix, ltext.fav_events),
                                        web: compose(ltext.featured, ltext.fav_events),
                                        dft: compose(ltext.popular, ltext.fav_events),
                                    },
                                    14: {
                                        loggedIn: compose(ltext.header_prefix, ltext.fav_spaces),
                                        web: compose(ltext.suggested, ltext.fav_spaces),
                                        dft: compose(ltext.popular, ltext.fav_spaces),
                                    },
                                    16: {
                                        loggedIn: compose(ltext.header_prefix, ltext.fav_resources),
                                        web: compose(ltext.suggested, ltext.fav_resources),
                                        dft: compose(ltext.popular, ltext.fav_resources),
                                    },
                                    101: {
                                        loggedIn: compose(ltext.header_prefix, ltext.fav_event_searches),
                                        dft: compose(ltext.public, ltext.fav_event_searches),
                                    },
                                    104: {
                                        loggedIn: compose(ltext.header_prefix, ltext.fav_location_searches),
                                        dft: compose(ltext.public, ltext.fav_location_searches),
                                    },
                                    106: {
                                        loggedIn: compose(ltext.header_prefix, ltext.fav_resource_searches),
                                        dft: compose(ltext.public, ltext.fav_resource_searches),
                                    },
                                    1000: ltext.reports,
                                    1010: ltext.tasks,
                                    2000: "Recently Viewed",
                                    3000: ltext.quicksearch,
                                    4000: ltext.event_creation,
                                    5000: ltext.event_creation_button,
                                };
                                let value = map[typeId];
                                if (typeof value === "string") {
                                    return value;
                                }
                                return (loggedIn && value.loggedIn) || (userTypeWeb && value.web) || value.dft;
                            },
                        },
                        c: {
                            icon: function (typeId: number) {
                                switch (typeId) {
                                    case 2000:
                                        return undefined;
                                    case 3000:
                                        return "iconHelp";
                                    default:
                                        return "icon-refresh";
                                }
                            },
                            tooltip: function (typeId: number) {
                                switch (typeId) {
                                    case 2000:
                                        return undefined;
                                    case 3000:
                                        return undefined;
                                    case 4000:
                                        return ltooltips.start_over;
                                    default:
                                        return ltooltips.refresh_section;
                                }
                            },
                        },
                        d: {
                            tooltip: function (isCollapsed: any) {
                                return isCollapsed ? ltooltips.expand : ltooltips.collapse;
                            },
                            no_collapse: { 4000: true },
                        },
                    },
                    tthelp: {
                        1: { content: ltooltips.events },
                        3000: {
                            content: ltooltips.quicksearch,
                            helplinkText: ltooltips.more_info,
                            helplinkId: "home_search",
                        },
                        51: { content: ltooltips.drafts, helplinkText: ltooltips.more_info, helplinkId: "home_drafts" },
                    },
                },
                recently_viewed: list, // recently_viewed is the same as list, but has its own typeId
                list: list,
                quick_search: {
                    style: {
                        panel: "My25Live_QuickSearch",
                        body: "My25Live_QuickSearch_Body",
                        item: {
                            3001: "My25Live_QuickSearch_Events",
                            3004: "My25Live_QuickSearch_Locations",
                            3006: "My25Live_QuickSearch_Resources",
                            3002: "My25Live_QuickSearch_Organizations",
                        },
                    },
                    text: {
                        3001: { name: ltext.search_events, button: ltext.go },
                        3004: { name: ltext.search_locations, button: ltext.go },
                        3006: { name: ltext.search_resources, button: ltext.go },
                        3002: { name: ltext.search_organizations, button: ltext.go },
                    },
                    alert: {
                        too_short_search_term: ltext.too_short_search_term,
                        invalid_event_locator: lcontroller.text.invalid_event_locator,
                    },
                    helplinkId: "home_search",
                    itemsloading: ltext.loading,
                },
                available_space: {
                    states: { 4000: "available_space", 4001: "find_location", 4002: "find_time" },
                    style: { panel: "My25Live_EventCreation" },
                    text: { 4001: ltext.find_location_prompt, 4002: ltext.find_availability },
                    divider: { style: "My25Live_EventCreation_Divider", text: ltext.or },
                    find_location: {
                        style: {
                            panel: "My25Live_EventCreation_FindLocation",
                            body: "My25Live_EventCreation_FindLocation_Body",
                            search_within: "My25Live_EventCreation_Collections",
                            attendees: "My25Live_EventCreation_Attendees",
                        },
                        text: {
                            enter_the_desired_date: ltext.enter_the_desired_date,
                            date: ltext.date,
                            start_time: ltext.start_time,
                            end_time: ltext.end_time,
                            number_of_attendees: ltext.number_of_attendees,
                            required: ltext.required,
                            show_locations_prompt: ltext.show_locations_prompt,
                            search_within: ltext.search_within,
                            select_your_location_collection: ltext.select_your_location_collection,
                            attendees_alert: ltext.attendees_alert,
                            location_collection_alert: ltext.location_collection_alert,
                            all_locations: ltext.all_locations,
                            loading: ltext.loading,
                        },
                        button: {
                            style: {
                                id: "My25Live_EventCreation_AvailableLocationTrigger",
                                cls: "My25Live_EventCreation_Button",
                            },
                        },
                        space_avail_results: {
                            loading: {
                                style: "My25Live_EventCreation_FindingLocations",
                                text: ltext.finding_locations,
                            },
                            list: {
                                style: {
                                    body: "My25Live_EventCreation_AvailableLocationResults",
                                    item: "My25Live_EventCreation_ResultItem",
                                },
                                text: { use_this_location: ltext.use_this_location, max_capacity: ltext.max_capacity },
                                col: {
                                    a: {
                                        col: {
                                            a: { icon: "icon-space" },
                                            b: { style: "bold" },
                                        },
                                    },
                                    b: { style: "My25Live_EventCreation_ResultItem_Use" },
                                },
                            },
                            desc: {
                                style: "My25Live_EventCreation_AvailableLocationResultDesc",
                                text: {
                                    locationResults: function (capacity: any, count: number) {
                                        let replaceCapacity = function (str: any, capacity: any) {
                                            return str
                                                .replace(/<span c:id="min">.*?<\/span>/, capacity.min)
                                                .replace(/<span c:id="max">.*?<\/span>/, capacity.max);
                                        };
                                        switch (count) {
                                            case 0:
                                                return replaceCapacity(ltext.no_matching_locations, capacity);
                                            default:
                                                return replaceCapacity(ltext.location_results, capacity)
                                                    .replace(/<span c:id="tally">.*?<\/span>/, count)
                                                    .replace(
                                                        /<span c:id="locations">.*?<\/span>/,
                                                        count === 1 ? ltext.location : ltext.locations,
                                                    );
                                        }
                                    },
                                    check_larger_capacity: ltext.s25_ng_check_larger_capacity,
                                    no_larger_capacity: ltext.s25_ng_no_larger_capacity,
                                    check_smaller_capacity: ltext.check_smaller_capacity,
                                },
                            },
                            try_advanced_search: {
                                style: "My25Live_EventCreation_AdvancedSearchLink",
                                text: ltext.try_advanced_search,
                            },
                        },
                    },
                    find_time: {
                        style: {
                            panel: "My25Live_EventCreation_FindTime",
                            try_browse: "My25Live_EventCreation_BrowseLink",
                            search: "My25Live_EventCreation_AllLocations_Search",
                            search_input: "My25Live_EventCreation_LocationKeyword",
                        },
                        text: {
                            find_location_byname: ltext.find_location_byname,
                            button: ltext.go,
                            try_browse: ltext.try_browse,
                        },
                        space_availability_container: {
                            tooltip_help: {
                                content: ltooltips.space_date,
                                helplinkId: "home_search",
                                helplinkText: ltooltips.more_info,
                            },
                            style: {
                                panel: "My25Live_EventCreation_AllLocations_Container",
                                spaces_select_container: "My25Live_EventCreation_AllLocations_SelectContainer",
                                spaces_select: "My25Live_EventCreation_AllLocations",
                                spaces_select_icon: "icon-space",
                                space_date: "My25Live_EventCreation_SomewhereBetween",
                                button: "aw-button aw-button--primary",
                            },
                            text: {
                                select_your_location: ltext.select_your_location,
                                space_date: ltext.space_date,
                                button: ltext.location_availability,
                                spaces_select_alert: ltext.spaces_select_alert,
                                loading: ltext.loading,
                                no_results: ltext.no_results,
                            },
                        },
                    },
                },
                create_event: {
                    style: {
                        panel: "My25Live_ScheduleEvent",
                        body: "My25Live_ScheduleEvent_Body",
                        button: "My25Live_btnScheduleEvent",
                    },
                    text: { button: ltext.create_button },
                    // s25-my25live.xml <a href="javascript:void(0)" b:help="event_create" b:behavior="DirectHelpLink"><s:textnode b:label="{$tLang/tooltips/more_info}"/></a>
                    tthelp: {
                        content: ltooltips.create_button,
                        helplinkText: ltooltips.more_info,
                        helplinkId: "event_create",
                    },
                },
            },
            section: {
                type: { 1: "regular", 2: "toolbox" },
                toolbox: {
                    style: {
                        section: "toolboxHolder",
                        body: "toolbox",
                        instructions: "toolboxInstructions",
                        button: { done: "doneButton", reset: "dashboardResetButton" },
                    },
                    text: {
                        instructions: {
                            customize: dashboard.toolbox_instructions_customize,
                            add: dashboard.toolbox_instructions_add,
                            remove: dashboard.toolbox_instructions_remove,
                            keyboard: dashboard.toolbox_instructions_keyboard,
                        },
                        button: { done: dashboard.done_button, reset: dashboard.reset_button },
                        reset: dashboard.reset_confirm,
                    },
                },
                customize: {
                    a: { icon: "settings--cog", style: "My25Live_Customize", text: dashboard.toolbox_prompt },
                    b: { icon: "help", style: "My25Live_Help", text: dashboard.help },
                },
            },
            dash: {
                text: { loading: ltext.dashboard.loading },
            },
        };
    }
}
