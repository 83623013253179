import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { S25ItemI } from "../../pojo/S25ItemI";
import { MasterDefinitionTagsService } from "../../services/master.definitions/master.definition.tags.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { PopoverComponent } from "../s25-popover/popover.component";

@TypeManagerDecorator("s25-ng-md-tag-multiselect")
@Component({
    selector: "s25-ng-md-tag-multiselect",
    template: `
        <span class="ngListItemParent ngBlock">
            <!--<span *ngIf="!selectedItems.length">-</span>-->
            <span class="ngCpointer" *ngIf="numLimit < selectedItems.length" (click)="toggleView()">{{
                showAll ? "&#x25B2;" : "&#x25BC;"
            }}</span>
            <span
                *ngFor="
                    let item of selectedItems | slice: 0 : (showAll ? selectedItems.length : numLimit - 1);
                    index as i;
                    last as isLast
                "
            >
                <span class="ngListCellArraySubItem">
                    <span>{{ item.itemName + (!isLast ? ", " : "") }}</span>
                </span>
            </span>
            <span *ngIf="!showAll && numLimit < selectedItems.length">({{ selectedItems.length }})</span>
        </span>

        <s25-ng-multiselect-search-criteria
            *ngIf="init"
            [type]="'systemTags'"
            [honorMatching]="true"
            [selectedItems]="selectedItems"
            [modelBean]="selectBean"
            [popoverOnBody]="true"
        >
        </s25-ng-multiselect-search-criteria>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25MdTagMultiSelectComponent implements OnInit {
    init = false;
    @Input() selectedItems: S25ItemI[] = [];
    @Input() selectBean: MultiselectModelI;
    @Input() numLimit = 5;

    @Output() selectedItemsChange: EventEmitter<MultiselectModelI> = new EventEmitter<MultiselectModelI>();

    @ViewChild("multiSelect") popoverTemplate: TemplateRef<any>;
    @ViewChild(PopoverComponent) popoverChild: PopoverComponent;

    showAll = false;

    constructor(
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {}

    onOpen = () => {};

    toggleView() {
        this.showAll = !this.showAll;
        this.cd.detectChanges();
    }

    onClose = () => {
        if (!this.selectBean.closing) {
            this.selectBean.cancel(true); //cancel but skip calling popup close since we are already closed here
        } else {
            this.selectBean.closing = false;
        }
    };

    async ngOnInit() {
        const tagsList = await MasterDefinitionTagsService.getTags();

        S25Util.extend(this.selectBean, {
            items: tagsList.map((tag) => {
                return {
                    itemId: tag.tag_id,
                    itemName: tag.tag_name,
                };
            }),
            origItems: this.selectedItems,
            selectedItems: this.selectedItems,
            hasQuantity: false,
            hasFilter: true,
            textButton: false,
            preFetchItems: false,
            title: "Select System Tags",
            popoverTemplate: this.popoverTemplate,
            hasDone: true,
            onDone: () => {
                this.selectedItems = this.selectBean.selectedItems;
                this.selectBean.buttonText = this.selectedItems?.length ? "Edit" : "Add";
                this.selectedItemsChange.emit(this.selectBean);
            },
            buttonText: this.selectedItems?.length ? "Edit" : "Add",
            // onCommit: this.save,
        });

        this.zone.run(() => {
            this.selectBean.popoverTemplate = this.popoverTemplate;
            this.selectBean.popoverClass = "s25-multiselect-popup";
            this.init = true;
            this.cd.detectChanges();
        });
    }
}
