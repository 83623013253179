import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from "@angular/core";
import { DataAccess } from "../../dataaccess/data.access";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { NotificationService } from "../../services/notification.service";

@TypeManagerDecorator("s25-master-definition-usage-report")
@Component({
    selector: "s25-master-definition-usage-report",
    template: `
        <div *ngIf="isInit">
            <button class="aw-button aw-button--outline" (click)="runReport()">View</button>
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25MasterDefinitionUsageReportComponent implements OnInit {
    @Input() type: string;
    @Input() itemId: number;

    isInit = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.isInit = true;
        this.cd.detectChanges();
    }

    runReport() {
        let objectType = "";
        let masterListType = this.type;

        if (masterListType.indexOf("event") > -1) {
            objectType = "event";
        } else if (masterListType.indexOf("location") > -1) {
            objectType = "space";
        } else if (masterListType.indexOf("resource") > -1) {
            objectType = "resource";
        } else if (masterListType.indexOf("org") > -1) {
            objectType = "organization";
        } else if (masterListType.indexOf("contact") > -1) {
            objectType = "contact";
        } else if (masterListType.indexOf("group") > -1) {
            masterListType = "group";
            objectType = "rate";
        }

        if (masterListType.indexOf("category") > -1) {
            masterListType = "category";
        } else if (masterListType.indexOf("role") > -1) {
            masterListType = "role";
        } else if (masterListType.indexOf("requirement") > -1) {
            masterListType = "requirement";
            objectType = "event";
        } else if (masterListType === "feature") {
            objectType = "space";
        } else if (masterListType === "layout") {
            objectType = "space";
        } else if (masterListType === "partition") {
            objectType = "space";
        } else if (masterListType === "org_type" || masterListType === "event_type") {
            masterListType = "type";
        } else if (masterListType === "org_rating") {
            masterListType = "rating";
        } else if (masterListType.indexOf("custom_attribute") > -1) {
            masterListType = "cust_atrb";
        }

        let url = DataAccess.getUrl(
            `/master_list.xml?otransform=MasterDefUsageExcel.xsl&master_list_id=${this.itemId}&master_list_type=${masterListType}&object_type=${objectType}`,
        );
        window.open(url);
        NotificationService.post("Report will be downloaded upon completion");
    }
}
