import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    signal,
    ViewChild,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import {
    MasterDefData,
    MasterDefinitionService,
    MasterDefType,
} from "../../services/master.definitions/master.definition.service";
import { Table } from "../s25-table/Table";
import { S25EditableTextComponent } from "../s25-editable/s25-editable-text/s25.editable.text.component";
import { S25CheckboxComponent } from "../s25-checkbox/s25.checkbox.component";
import {
    PolicyInputData,
    S25NotificationPolicyComponent,
} from "../s25-notification-policy/s25.notification.policy.component";
import { S25MdTagMultiSelectComponent } from "./s25.md.tag.picker.component";
import { S25MasterDefinitionsUsageComponent } from "./s25-master-definitions-usage/s25.master.definitions.usage.component";
import { S25MasterDefinitionUsageReportComponent } from "./s25.master.defs.usage.report.component";
import { GenericTableButtonComponent } from "../s25-table/generics/generic.table.button.component";
import { Bind } from "../../decorators/bind.decorator";
import { S25Util } from "../../util/s25-util";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import { PreferenceService } from "../../services/preference.service";
import { jSith } from "../../util/jquery-replacement";
import { FlsService } from "../../services/fls.service";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { UserprefService } from "../../services/userpref.service";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { MasterDef } from "../../pojo/MasterDefs";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { MasterDefinitionTagsService } from "../../services/master.definitions/master.definition.tags.service";

@TypeManagerDecorator("s25-ng-master-definition")
@Component({
    selector: "s25-ng-master-definition",
    template: ` @if (init()) {
        <div class="master-defs--wrapper">
            <h2 class="c-margin-bottom--single">{{ header }}</h2>

            <div class="master-defs--action-wrapper">
                @if (type !== "event_type") {
                    <button
                        class="aw-button aw-button--primary master-defs-add-new"
                        (click)="create()"
                        [disabled]="isCreating()"
                    >
                        {{ isCreating() ? "" : "Create New" }}
                        <s25-loading-inline [model]="{}"></s25-loading-inline>
                    </button>
                }
            </div>

            @if (abridgedWarningText()) {
                <div class="rose-form--error"></div>
                <div class="rose-form--organization-rating-info c-margin-top--half c-margin-bottom--half">
                    <div class="rose-form--error-text">
                        <strong>No items are selected for the Abridged list.</strong><br />
                        {{ abridgedWarningText() }}
                    </div>
                </div>
            }

            <s25-ng-table
                [dataSource]="tableConfig"
                [rowSortable]="true"
                [sortOrderPreference]="type"
                [align]="'center'"
                [hasFilter]="true"
            ></s25-ng-table>

            <s25-ng-modal #forceDeleteModal [title]="'Confirm Deletion'" [type]="'deny'" [size]="'xs'">
                Are you sure that you want to delete this master definition and remove all references to it?
            </s25-ng-modal>

            <s25-ng-modal #npModal [title]="'Notification Policy'" [size]="'xs'">
                <ng-template #s25ModalBody>
                    <s25-ng-notification-policy [data]="policyData"></s25-ng-notification-policy>
                </ng-template>
                <ng-template #s25ModalFooter>
                    <button class="aw-button aw-button--danger--outline" (click)="deletePolicy()">Delete</button>
                    <button class="aw-button aw-button--primary" (click)="savePolicy()">Save</button>
                </ng-template>
            </s25-ng-modal>
        </div>
    }`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25MasterDefinitionComponent implements OnInit {
    @Input() type: MasterDefType;

    @ViewChild("forceDeleteModal") forceDeleteModal: S25ModalComponent;
    @ViewChild("npModal") npModal: S25ModalComponent;
    @ViewChild(S25NotificationPolicyComponent) notificationPolicyComp: S25NotificationPolicyComponent;
    @ViewChild(S25TableComponent) tableComp: S25TableComponent;

    init = signal<boolean>(false);
    abridgedWarningText = signal<string>("");
    isCreating = signal<boolean>(false);
    prefMap: { [key: MasterDefType]: { [key: number]: boolean } } = {};
    data: MasterDefData;
    tableConfig: Table.DataSource;
    policyData: PolicyInputData;
    canEditNP: boolean;
    groupId: number;

    get header() {
        const header = this.type
            .split("_")
            .map((char) => `${char[0].toUpperCase()}${char.substring(1).toLowerCase()}`)
            .join(" ");

        if (header.at(-1) === "y") return header.slice(0, -1) + "ies";

        return header + "s";
    }

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        this.data = MasterDefinitionService.masterDefsConst[this.type];

        const [groupId, fls] = await Promise.all([
            UserprefService.getGroupId(),
            this.type.indexOf("requirement") > -1 && FlsService.getFls(),
        ]);

        this.groupId = groupId;
        if (fls) this.canEditNP = fls.EVENT_NOTIFY === "F";

        this.tableConfig = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: this.setTableColumns(),
        };

        this.zone.run(() => this.init.set(true));
    }

    @Bind
    async getRows() {
        const [rows, prefs] = await Promise.all([
            MasterDefinitionService.get(this.type),
            this.data.prefs && PreferenceService.getPreferences(this.data.prefs as string[]),
        ]);

        prefs &&
            jSith.forEach(prefs, (prefName, obj) => {
                this.prefMap[prefName] ??= {};

                const prefIds = S25Util.toStr(obj?.value).split(",").filter(S25Util.isTruthy).map(S25Util.toInt) ?? [];
                if (!prefIds.length) {
                    this.setWarningText(this.data.prefs[0]);
                }

                prefIds.forEach((itemId) => {
                    this.prefMap[prefName][itemId] ??= true;
                });
            });

        return {
            rows: rows?.map(this.mapToRows),
        };
    }

    @Bind
    mapToRows(row: MasterDef): Table.Row {
        const { idProp, nameProp, prefs, hasAllowQty, hasAllowComment, hasNotification, hasTags, nameLen } = this.data;
        const id = row[idProp];
        const rowName = row[nameProp].toString();

        return {
            id: id,
            name: rowName,
            cells: {
                id: { text: id },
                name: {
                    component: S25EditableTextComponent,
                    inputs: {
                        val: rowName,
                        hasCommit: true,
                        hasCommitButton: true,
                        hasCancelButton: true,
                        min: 1,
                        max: nameLen,
                        customValidation: (newVal: string) => {
                            return this.onNameChange(newVal, id);
                        },
                    },
                },
                active: {
                    component: S25CheckboxComponent,
                    inputs: { modelValue: row.defn_state },
                    outputs: {
                        modelValueChange: (isChecked: boolean) => this.handleCheckboxChange("setActive", id, isChecked),
                    },
                },
                ...(prefs?.length && {
                    abridged: {
                        component: S25CheckboxComponent,
                        inputs: { modelValue: this.prefMap[prefs[0]][id] },
                        outputs: { modelValueChange: (isChecked: boolean) => this.updateAbridged(id, isChecked) },
                    },
                }),
                ...(hasAllowQty && {
                    allowQty: {
                        component: S25CheckboxComponent,
                        inputs: { modelValue: row.stock_count >= 0 },
                        outputs: {
                            modelValueChange: (isChecked: boolean) =>
                                this.handleCheckboxChange("setStockCount", id, isChecked),
                        },
                    },
                }),
                ...(hasAllowComment && {
                    allowComment: {
                        component: S25CheckboxComponent,
                        inputs: { modelValue: row.allow_comment },
                        outputs: {
                            modelValueChange: (isChecked) => this.handleCheckboxChange("setReqComment", id, isChecked),
                        },
                    },
                }),
                ...(hasNotification &&
                    this.canEditNP && {
                        notification: {
                            component: S25NotificationPolicyComponent,
                        },
                    }),
                ...(hasTags && {
                    tags: {
                        component: S25MdTagMultiSelectComponent,
                        inputs: {
                            selectedItems: S25Util.array
                                .forceArray(row.tags)
                                .map((tag) => ({ itemId: tag.tag_id, itemName: tag.tag_name })),
                            selectBean: { hasCommit: true },
                        },
                        outputs: {
                            selectedItemsChange: (selectBean) => this.onTagsUpdate(selectBean, id),
                        },
                    },
                }),
                usage: {
                    component: S25MasterDefinitionsUsageComponent,
                    inputs: { type: this.type, itemId: id },
                },
                report: {
                    component: S25MasterDefinitionUsageReportComponent,
                    inputs: { type: this.type, itemId: id },
                },
                delete: {
                    inputs: { label: "Delete", type: "danger--outline", disabled: id < 0 },
                },
                ...(this.groupId === -1 && {
                    force: {
                        inputs: { label: "Force Delete", type: "danger--outline", disabled: id < 0 },
                    },
                }),
            },
        };
    }

    setTableColumns(): Table.Column[] {
        return [
            { id: "id", header: "ID", width: "min-content" },
            { id: "name", header: "Name", content: { component: S25EditableTextComponent } },
            { id: "active", header: "Active", content: { component: S25CheckboxComponent }, width: 66 },
            ...(this.data.prefs
                ? [
                      {
                          id: "abridged",
                          header: "Abridged",
                          content: { component: S25CheckboxComponent },
                          width: 66,
                      },
                  ]
                : []),
            ...(this.data.hasAllowQty
                ? [
                      {
                          id: "allowQty",
                          header: "Allow Quantity",
                          content: { component: S25CheckboxComponent },
                          width: 66,
                      },
                  ]
                : []),
            ...(this.data.hasAllowComment
                ? [
                      {
                          id: "allowComment",
                          header: "Allow Comment",
                          content: { component: S25CheckboxComponent },
                          width: 66,
                      },
                  ]
                : []),
            ...(this.data.hasNotification && this.canEditNP
                ? [
                      GenericTableButtonComponent.Column(
                          "Show",
                          this.initNotificationModal,
                          "outline",
                          75,
                          null,
                          "Notification Policy",
                      ),
                  ]
                : []),
            ...(this.data.hasTags
                ? [
                      {
                          id: "tags",
                          header: "Tags",
                          width: 120,
                          content: { component: S25MdTagMultiSelectComponent },
                      },
                  ]
                : []),
            {
                id: "usage",
                header: "Usage",
                width: 75,
                content: { component: S25MasterDefinitionsUsageComponent },
            },
            {
                id: "report",
                header: "Report",
                width: 75,
                content: { component: S25MasterDefinitionUsageReportComponent },
            },
            GenericTableButtonComponent.Column("Delete", this.delete, "danger--outline", 75),
            ...(this.groupId === -1
                ? [
                      GenericTableButtonComponent.Column(
                          "force",
                          this.forceDelete,
                          "danger--outline",
                          75,
                          null,
                          "Force Delete",
                      ),
                  ]
                : []),
        ];
    }

    @Bind
    async delete(row: Table.Row, instance: GenericTableButtonComponent) {
        instance.isLoading = true;
        try {
            await MasterDefinitionService.delete(this.type, row.id as number);
            !!this.data.prefs && (await this.updateAbridged(row.id as number, false));
            return this.tableComp.refresh();
        } catch (e) {
            S25Util.showError(e);
            instance.isLoading = false;
        }
    }

    @Bind
    async forceDelete(row: Table.Row, instance: GenericTableButtonComponent) {
        instance.isLoading = true;
        const yes = await this.forceDeleteModal.open();

        if (yes) {
            try {
                await MasterDefinitionService.forceDelete(this.type, row.id as number);
                !!this.data.prefs && (await this.updateAbridged(row.id as number, false));
                return this.tableComp.refresh();
            } catch (e) {
                S25Util.showError(e);
                instance.isLoading = false;
            }
        } else {
            instance.isLoading = false;
        }
    }

    setWarningText(prefName: string) {
        let warningText = "All items will be visible, even to users with the Group Administration tool permission, ";

        switch (true) {
            case prefName.indexOf("sp_") > -1:
                warningText +=
                    'Basic > "15.0 View All Location Master Definitions (Not Just Abridged List)" set to “No."';
                break;
            case prefName.indexOf("rs_") > -1:
                warningText +=
                    'Basic > "17.0 View All Resource Master Definitions (Not Just Abridged List)" set to “No.”';
                break;
            case prefName.indexOf("org_") > -1:
                warningText +=
                    'Basic > "19.0 View All Organization Master Definitions (Not Just Abridged List)" set to “No.”';
                break;
            case prefName.indexOf("ev_") > -1:
                warningText += 'Basic > "13.0 View All Event Master Definitions (Not Just Abridged List)" set to “No.”';
                break;
            default:
                warningText = "";
        }

        this.abridgedWarningText.set(warningText);
    }

    @Bind
    async initNotificationModal(row: Table.Row) {
        this.policyData = {
            itemId: row.id as number,
            type: this.type,
            canEdit: true,
            inModal: true,
        };

        await this.npModal.open();
    }

    async savePolicy() {
        const resp = await this.notificationPolicyComp?.save();
        if (resp?.results?.info?.msg_id === "EV_I_SAVED") return this.npModal.close();
    }

    async deletePolicy() {
        const resp = await this.notificationPolicyComp?.delete();
        if (resp?.results?.info?.msg_id === "NP_I_DELETED") return this.npModal.close();
    }

    async create() {
        const buttonEl = this.elementRef.nativeElement.querySelector(".master-defs-add-new");
        S25LoadingApi.init(buttonEl);
        this.isCreating.set(true);

        let params = null;
        if (this.type.indexOf("requirement") > -1) {
            params = {
                requirement_type: this.type === "other_requirement" ? 6 : 7,
                stock_count: this.type === "other_requirement" ? 0 : -1,
                allow_comment: 1,
            };
        }

        try {
            await MasterDefinitionService.createNew(this.type, params);
            await this.tableComp.refresh();
        } catch (e) {
            console.error(e);
        }

        S25LoadingApi.destroy(buttonEl);
        this.isCreating.set(false);
    }

    async handleCheckboxChange(prop: string, id: number, isChecked: boolean) {
        try {
            await (MasterDefinitionService as any)[prop](this.type, id, isChecked);
        } catch (e) {
            S25Util.showError(e);
        }
    }

    async updateAbridged(actionId: number, isChecked: boolean) {
        if (isChecked) {
            this.prefMap[this.data.prefs[0]][actionId] = true;
        } else {
            delete this.prefMap[this.data.prefs[0]][actionId];
        }

        const newValue = Object.keys(this.prefMap[this.data.prefs[0]]).join(",");
        try {
            await PreferenceService.setPreference(this.data.prefs[0], newValue, "S");

            if (newValue?.length < 1) {
                this.setWarningText(this.data.prefs[0]);
            } else {
                this.abridgedWarningText.set("");
            }
        } catch (e) {
            S25Util.showError(e);
        }
    }

    @Bind
    onTagsUpdate(tagsModel: MultiselectModelI, id: number) {
        const added = tagsModel.addedItems?.map((items) => items.itemId);
        const removed = tagsModel.removedItems?.map((items) => items.itemId);

        if (!added?.length && !removed?.length) return;

        return MasterDefinitionTagsService.editTagsOnObject(
            this.data.tagProp,
            null,
            id,
            added as number[],
            removed as number[],
        );
    }

    @Bind
    async onNameChange(newName: string, id: number) {
        try {
            await MasterDefinitionService.setName(this.type, id, newName);
            return true;
        } catch (e) {
            if (e.error?.results?.error?.msg_id === "ML_E_STORE") {
                return "This name is already in use";
            }
        }
    }
}
