import { DataAccess } from "../../../dataaccess/data.access";
import { SpaceService } from "../../../services/space.service";
import { jSith } from "../../../util/jquery-replacement";
import { S25Util } from "../../../util/s25-util";
import { Cache } from "../../../decorators/cache.decorator";

var NON_BLACKOUT_PARMS = [
    "obj_cache_accl",
    "mode",
    "comptype",
    "compsubject",
    "state",
    "reservations_state",
    "sort",
    "include_related_events",
];
var MAX_BLACKOUT_SUBJECTS = 3;

let blackoutKey = function (blackout: any) {
    return blackout.init_start_dt + "&" + blackout.init_end_dt + "&" + blackout.profile_name;
};

let getDate = function (dateOverride: any, params: any, isEndDate?: boolean) {
    return S25Util.date.toS25ISODateStr(
        dateOverride ||
            (!isEndDate
                ? S25Util.date.firstDayOfWeek(params.modelBean.optBean.date, params.weekstart)
                : S25Util.date.lastDayOfWeek(
                      params.modelBean.optBean.endDate || params.modelBean.optBean.date,
                      params.weekstart,
                  )),
    );
};

export class CalendarService {
    public static setBlackouts(blackouts: any, rootData: any, truncated: any) {
        var blackoutDateHash: any = {};
        blackouts && blackouts.blackout && blackouts.blackout.reverse();

        jSith.forEach(blackouts && blackouts.blackout, function (_, blackout) {
            blackout.date && blackout.date.reverse();
            jSith.forEach(blackout.date, function (_, blackoutDate) {
                jSith.forEach(
                    S25Util.array.forceArray(S25Util.propertyGet(rootData, "events")).filter(S25Util.isDefined),
                    function (_, date) {
                        var newBlackoutRsrvs = [];
                        if (
                            S25Util.date.isBetweenEqual(
                                date.date,
                                S25Util.date.getDate(blackoutDate.start_dt),
                                S25Util.date.toEndOfDay(blackoutDate.end_dt),
                            )
                        ) {
                            if (!blackoutDateHash[date.date + "&" + blackoutKey(blackout)]) {
                                blackoutDateHash[date.date + "&" + blackoutKey(blackout)] = true;
                                date.rsrv = date.rsrv || [];
                                var blackoutRsrv = {
                                    event_name: blackout.profile_name, //remove blackout prefix ANG-4021
                                    rsrv_start_dt: blackoutDate.start_dt,
                                    rsrv_end_dt: blackoutDate.end_dt,
                                    subject: blackout.room
                                        .map(function (room: any) {
                                            return {
                                                itemId: room.room_id,
                                                itemName: room.room_short,
                                                itemTypeId: 4,
                                                has_more:
                                                    truncated && blackout.room.length > MAX_BLACKOUT_SUBJECTS ? 1 : 0,
                                            };
                                        })
                                        .filter(function (item: any, i: number) {
                                            return !truncated || i < MAX_BLACKOUT_SUBJECTS;
                                        }),
                                };

                                newBlackoutRsrvs.push(blackoutRsrv);
                            }
                        }

                        date.rsrv = date.rsrv || [];
                        date.rsrv = [].concat(newBlackoutRsrvs, date.rsrv);
                    },
                );
            });
        });
    }

    public static getBlackouts(params: any, dateOverride: boolean) {
        var paramArr = CalendarService.defaultParams(params, dateOverride).split(/[\?&]/);
        paramArr.shift();
        paramArr = paramArr
            .filter(function (p) {
                for (var i = 0; i < NON_BLACKOUT_PARMS.length; i++) {
                    if (p.indexOf(NON_BLACKOUT_PARMS[i] + "=") > -1) {
                        return false;
                    }
                }
                return true;
            })
            .map(function (p: string) {
                return p && p.replace("spaces_", "");
            });

        var searchQuery = "?" + paramArr.join("&");
        return SpaceService.getBlackouts(searchQuery);
    }

    public static defaultParams(params: any, dateOverride?: boolean) {
        let startDate = getDate(dateOverride, params);
        let endDate = getDate(dateOverride, params, true);
        if (params.modelBean.optBean.multiQueryStr) {
            params.modelBean.searchQuery = S25Util.getMultiQuery(
                params.modelBean.searchQuery,
                params.modelBean.optBean.multiQueryStr,
            );
        }

        if (params.modelBean.searchQuery) {
            //calendar uses reservations.xml, which has its own state so we need to explicitly use events_state as that is what search queries are referring to...
            params.modelBean.searchQuery = params.modelBean.searchQuery.replace("&state=", "&events_state=");
        }

        return (
            "/home/calendar/calendardata.json?mode=pro&obj_cache_accl=" +
            (params.modelBean.optBean.useCache === false ? "force" : "0") +
            "&start_dt=" +
            startDate +
            "&end_dt=" +
            endDate +
            "&comptype=" +
            params.comptype +
            "&sort=evdates_event_name" +
            "&compsubject=" +
            params.modelBean.optBean.compsubject +
            (params.modelBean.searchQuery ? params.modelBean.searchQuery : "") +
            (params.modelBean.optBean.includeAddtl ? "&include_addtl_time=T" : "") +
            (params.modelBean.optBean.showRelatedEvents ? "&include_related_events=T" : "") +
            (params.modelBean.optBean.compsubject === "location" && params.modelBean.optBean.showRelatedLocations
                ? "&include=related"
                : "") +
            (["event", "organization", "location", "resource"].indexOf(params.modelBean.optBean.compsubject) > -1
                ? !params.modelBean.searchQuery || params.modelBean.searchQuery.indexOf("&state=") === -1
                    ? "&state=0+1+3+4+99"
                    : ""
                : !params.modelBean.searchQuery || params.modelBean.searchQuery.indexOf("&reservations_state=") === -1
                  ? "&reservations_state=0+1+3+4+99"
                  : "")
        );
    }

    public static hasMoreParams(params: any, dateOverride?: any) {
        let startDate = getDate(dateOverride, params);
        let endDate = getDate(dateOverride, params, true);
        return (
            "/home/calendar/calhasmore.json?mode=pro&start_dt=" +
            startDate +
            "&end_dt=" +
            endDate +
            "&obj_cache_accl=" +
            params.modelBean.data.root.root.obj_id +
            "&compsubject=" +
            params.modelBean.optBean.compsubject
        );
    }

    public static angularParams(params: any) {
        if (params.comptype === "home") {
            return "&" + params.modelBean.optBean.chosen.obj.val;
        } else {
            return "";
        }
    }

    public static getData(params: any) {
        return DataAccess.get(
            DataAccess.injectCaller(
                CalendarService.defaultParams(params) + CalendarService.angularParams(params),
                "CalendarService.getData",
            ),
        );
    }

    @Cache({ immutable: true, targetName: "CalendarService" })
    public static getDataCached(params: any) {
        return CalendarService.getData(params);
    }

    public static getCalendarDayPage(
        objId: string,
        date: Date,
        comptype: string,
        compsubject: string,
        lastId: string,
        includeAddtl: string,
    ) {
        let url =
            objId &&
            "/home/calendar/calendardata.json?mode=pro&obj_cache_accl=" +
                objId +
                "&start_dt=" +
                S25Util.date.toS25ISODateStr(date) +
                "&end_dt=" +
                S25Util.date.toS25ISODateStr(date) +
                "&page=1" +
                "&comptype=" +
                comptype +
                (includeAddtl ? "&include_addtl_time=T" : "") +
                "&sort=evdates_event_name" +
                "&compsubject=" +
                compsubject +
                "&last_id=" +
                lastId;
        return CalendarService.getCalendarDayPageDao(url);
    }

    //Sepreate DAO for easier mocking
    public static getCalendarDayPageDao(url: string) {
        return DataAccess.get(DataAccess.injectCaller(url, "CalendarService.getCalendarDayPage"));
    }

    @Cache({ immutable: true, targetName: "CalendarService" })
    public static getHasMore(params: any) {
        return DataAccess.get(
            DataAccess.injectCaller(CalendarService.hasMoreParams(params), "CalendarService.getHasMore"),
        );
    }

    public static getCalChoose() {
        return DataAccess.get(DataAccess.injectCaller("/home/calendar/calchoose.json", "CalendarService.getCalChoose"));
    }

    public static scopeToDataRequest(scope: any) {
        return {
            comptype: scope.comptype,
            modelBean: {
                searchQuery: scope.modelBean.searchQuery,
                data: scope.modelBean.data,
                optBean: {
                    datePropertyValue: scope.modelBean.optBean.datePropertyValue,
                    date: scope.modelBean.optBean.date,
                    endDate: scope.modelBean.optBean.endDate,
                    useCache: scope.modelBean.optBean.useCache,
                    compsubject: scope.modelBean.optBean.compsubject,
                    chosen: scope.modelBean.optBean.chosen,
                    useServiceCache: scope.modelBean.optBean.useServiceCache,
                    showRelatedEvents: scope.modelBean.optBean.showRelatedEvents,
                    showRelatedLocations: scope.modelBean.optBean.showRelatedLocations,
                    includeAddtl: scope.modelBean.optBean.includeAddtl,
                    multiQueryStr: scope.modelBean.optBean.multiQueryStr,
                },
            },
            weekstart: scope.weekstart,
        };
    }

    public static generateCalendar(startDt: any, endDt: any, weekstart: number, comptype: any, rsrvData: any) {
        var daysArr = new Array(S25Util.date.diffDays(startDt, endDt) + 1);
        var daysPerRow = 7;
        var ret = {
            root: {
                comptype: comptype,
                days_per_row: daysPerRow,
                lastupdate: S25Util.date.toS25ISODateTimeStr(new Date()),
                events: daysArr,
                weekday: new Array(daysPerRow),
            },
        };
        var firstDayOfWeek = daysPerRow === 7 ? S25Util.date.firstDayOfWeek(new Date(), weekstart) : startDt,
            i,
            iDate;
        for (i = 0; i < ret.root.days_per_row; i++) {
            iDate = S25Util.date.addDays(firstDayOfWeek, i);
            ret.root.weekday[i] = S25Util.date.getDayName(iDate.getDay());
        }
        for (i = 0; i < ret.root.events.length; i++) {
            iDate = S25Util.date.addDays(startDt, i);
            var iDateStr = S25Util.date.toS25ISODateTimeStr(iDate);
            ret.root.events[i] = {
                is_start: i === 0 ? 1 : null,
                date: iDateStr,
                day: iDate.getDate(),
                month_name: i === 0 || iDate.getDate() === 1 ? S25Util.date.getMonthName(iDate) : null,
                rsrv: rsrvData[iDateStr],
            };
        }
        return ret;
    }

    public static getCacheParams(params: any, dateOverride: any) {
        return {
            query_id: params.modelBean.query_id,
            compsubject: params.modelBean.optBean.compsubject,
            start_dt: getDate(dateOverride, params),
            end_dt: getDate(dateOverride, params, true),
            include:
                params.modelBean.optBean.compsubject === "location" &&
                params.modelBean.optBean.showRelatedLocations &&
                "related",
            include_related_events: params.modelBean.optBean.showRelatedEvents && "T",
            include_addtl_time: params.modelBean.optBean.includeAddtl && "T",
            state:
                ["event", "organization", "location", "resource"].indexOf(params.modelBean.optBean.compsubject) > -1 &&
                (!params.modelBean.searchQuery || params.modelBean.searchQuery.indexOf("&state=") === -1) &&
                "0+1+3+4+99",
            reservations_state:
                ["event", "organization", "location", "resource"].indexOf(params.modelBean.optBean.compsubject) ===
                    -1 &&
                (!params.modelBean.searchQuery ||
                    params.modelBean.searchQuery.indexOf("&reservations_state=") === -1) &&
                "0+1+3+4+99",
        };
    }

    public static getDate(dateOverride: any, params: any, isEndDate?: boolean) {
        return getDate(dateOverride, params, isEndDate);
    }
}
