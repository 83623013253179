import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ModalService } from "../modal/modal.service";
import { Table } from "../s25-table/Table";
import { GenericTableButtonComponent } from "../s25-table/generics/generic.table.button.component";
import { Bind } from "../../decorators/bind.decorator";
import { MasterDefinitionTagsService } from "../../services/master.definitions/master.definition.tags.service";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25EditMasterDefinitionTagComponent } from "./s25.edit.master.defs.tag.component";
import { ModalEditTagComponent } from "./modal.edit.tag.component";
import { CacheRepository } from "../../decorators/cache.decorator";

export interface TagModel {
    title?: string;
    itemId?: number;
    masterDefs: MasterDef[];
}

export interface MasterDef {
    name: string;
    checked: boolean;
    selected?: boolean;
    allOrNone?: boolean;
}

@TypeManagerDecorator("s25-ng-tags-table")
@Component({
    selector: "s25-ng-tags-table",
    template: `
        <div *ngIf="init">
            <h2>Tag Management</h2>
            <p class="ngFinePrint c-margin-bottom--half">
                System tags can be associated with certain master definitions in 25live. They can be used to grant view
                access by security group in a more granular way than the abridged list allows.
            </p>
            <s25-ng-edit-tag-modal
                class="header"
                [title]="'Create New Tag'"
                [buttonClass]="'aw-button aw-button--primary'"
                (close)="refresh($event)"
            ></s25-ng-edit-tag-modal>
            <s25-ng-table
                [dataSource]="tableConfig"
                [rowSortable]="false"
                [columnSortable]="true"
                [hasFilter]="true"
                [hasRefresh]="true"
            ></s25-ng-table>
        </div>
    `,
    styles: `
        .header {
            display: flex;
            margin-bottom: 1em;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25TagsTableComponent implements OnInit {
    @ViewChild(S25TableComponent) table: S25TableComponent;
    @ViewChild(S25EditMasterDefinitionTagComponent) editForm: S25EditMasterDefinitionTagComponent;
    // @ViewChild(S25ModalComponent) createModal: S25ModalComponent;

    init = false;
    data: TagModel = {
        masterDefs: [
            { name: "Categories", checked: false },
            // { name: "Custom Attributes", checked: false },
            // { name: "Roles", checked: false },
            // { name: "Features", checked: false },
            // { name: "Layouts", checked: false },
            // { name: "Partitions", checked: false },
            // { name: "Org Types", checked: false },
            // { name: "Org Ratings", checked: false },
            // { name: "Event Types", checked: false },
            // { name: "Images", checked: false },
        ],
    };
    tableConfig: Table.DataSource;
    sets: Table.Row[];

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        this.tableConfig = {
            type: "unpaginated",
            columns: [
                { id: "name", header: "Name" },
                { id: "description", header: "Description" },
                {
                    id: "edit",
                    header: "Edit",
                    content: {
                        component: ModalEditTagComponent,
                        outputs: { close: this.refresh },
                    },
                    width: "min-content",
                    sortable: false,
                    align: "center",
                },
                GenericTableButtonComponent.Column("Delete", this.onDeleteClick, "danger--outline"),
            ],
            dataSource: this.getRows,
        };
        this.init = true;
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery) {
        query.forceRefresh && CacheRepository.invalidateByService("MasterDefinitionTagsService", "getTags");
        let tags = await MasterDefinitionTagsService.getTags();

        let rows: Table.Row[] = tags.map((tag) => {
            return {
                id: tag.tag_id,
                name: tag.tag_name,
                cells: {
                    name: { text: tag.tag_name },
                    description: { text: tag.comment },
                    edit: { inputs: { itemId: tag.tag_id } },
                },
            };
        });

        return { rows: rows };
    }

    @Bind
    async refresh(val: boolean) {
        val && (await this.table.refresh(true));
    }

    @Bind
    async onDeleteClick(row: Table.Row) {
        const message = `Are you sure you want to delete <b> ${row.name} </b>?`;
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: message,
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no

        const [_, error] = await S25Util.Maybe(MasterDefinitionTagsService.deleteTag(row.id as number));
        if (error) return S25Util.showError(error);
        return this.table.refresh(true);
    }
}
